import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    
    if(allowedRoles) {
                        
        //if(allowedRoles.every(reqRole => auth?.roles?.includes(reqRole))) {
        if((typeof allowedRoles === 'string' && auth?.roles?.includes(allowedRoles))
            || (typeof allowedRoles === 'object' && allowedRoles.anyOf && allowedRoles.anyOf.length && auth?.roles?.some(authRole => allowedRoles.anyOf.includes(authRole)))
            || (allowedRoles.constructor === Array && (!allowedRoles.length || allowedRoles.every(reqRole => auth?.roles?.includes(reqRole))))
           ) {
            return <Outlet />
        }
        else if(auth && auth.roles && auth.roles.length) { 
            return <Navigate to='/Unauthorized' />
        }
        else{
            return <Navigate to='/login' state={{ from: location }} replace />
        }
    }
    else {
        if(auth && auth.roles && auth.roles.length) {
            return <Outlet />
        }
        else{
            return <Navigate to='/login' state={{ from: location }} replace />
        }
    }
}

export default RequireAuth;
import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

// material-ui
import { useTheme } from '@mui/material/styles';
import config from '../../../../config';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    Divider,
    useMediaQuery
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton'

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';



// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Google from 'assets/images/icons/social-google.svg';

import { tokenParserInst } from 'utils/TokenParser';
import useEcapSnackNotification, { useEcapSnackNotificationHTML } from 'hooks/useEcapSnackNotification';
import useAuthConfig from 'hooks/useAuthConfig';
// import { ecapAuthNZHelper } from '../sso/ecapAuthNZHelper';
// import * as authTokenHelper from '../sso/authorizationtokenhelper';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const { auth, setAuth } = useAuth();
    const { authConfig, setAuthConfig } =  useAuthConfig();
    const [msg, sendNotification] = useEcapSnackNotification();
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const [authzInProgress, setAuthzInProgress] = useState(false);
    const [emailInp, setEmailInp] = useState('');


    const hubListener = (data) => {
        const { payload } = data
        console.log('A new auth event has happened: ', data)
         if (payload.event === 'signIn') {
            
            const groups = payload.data.signInUserSession.accessToken.payload["cognito:groups"];
            if((groups || []).includes("ECAP")) {
              cognitoSigninSuccess(payload.data);
            }
            else {
                setSnackbar({
                    html: "Not authorized for ECAP.",
                    variant: "error",
                })
            }
         }
         if (payload.event === 'signOut') {
           console.log('a user has signed out!')
         }
      }

    const authHubListen = useMemo(() => {
        Hub.listen('auth', (data) => {
            const { payload } = data
            console.log('A new auth event has happened: ', data)
             if (payload.event === 'signIn') {
                  cognitoSigninSuccess(payload.data);
             }
             else{
                
             }
             if (payload.event === 'signOut') {
               console.log('a user has signed out!')
             }
          })
    }, [])
      const [hubListCallback, setHubListCallback] = useState(null);

    useEffect( () => {
        /* later */
        Hub.remove('auth', hubListener)
        if(hubListCallback) {
            hubListCallback();
        }
        // const hubListDestroyer = 
        // authHubListen();
        // setHubListCallback(hubListDestroyer);
      }, [])

    const cognitoSigninSuccess = async (payloadData) => {

        var authReq = {
            //AppId: "ECAP-E2-TFI",
            // Email: values.email,
            // Password: values.password
            "tenantId":authConfig.tenantId,
            "appId": authConfig.applicationId,
            "appNameCode": authConfig.appName,
            "token": payloadData.signInUserSession.getIdToken().jwtToken
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(authReq),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // let changePassword = false;
        setAuthzInProgress(true); 
        
        let authResp = {statuses: []};
        var authzResp = await fetch(`${config.authZBaseApiUrl}/api/Authorize`, options)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    return res.json()
                }
                // else if (res.status === 480) {
                //     changePassword = true;
                // }
            })
            .catch(err => {
                console.log(err)
                sendNotification({ msg: 'error authenticating user', variant: 'error' })
            });
        authResp.statuses.push({
            authType: 2,
            isSuccess: true,
            tokenInfo: {
                accessToken: authzResp.token,
                expiresIn: authzResp.expiresIn
            }
        })

        var ecapWorkerReq = {
            AuthZToken: authzResp.token
        }
        const workerOptions = {
            method: 'POST',
            body: JSON.stringify(ecapWorkerReq),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        var workerResp = await fetch(`${config.baseApiUrl}/api/worker/token`, workerOptions)
        .then(res => {
            console.log(res)
            if (res.status === 200) {
                return res.json()
            }
            // else if (res.status === 480) {
            //     changePassword = true;
            // }
        })
        .catch(err => {
            console.log(err)
            sendNotification({ msg: 'error authenticating user', variant: 'error' })
        });

        authResp.statuses.push({
            authType: 3,
            isSuccess: true,
            tokenInfo: {
                accessToken: workerResp.token,
                expiresIn: workerResp.expiresIn
            }
        })

        // if (changePassword) {

        //     navigate('/ChangePassword', { state: { email: values.email, forceChange: true } });
        //     return;
        // }
        authResp.email = emailInp;
        let parsedAuthResp = await parseAuthResponse(authResp, authConfig, true);
        
        if (parsedAuthResp.isSuccess) {
            localStorage.setItem("AuthDetails", JSON.stringify(parsedAuthResp));
        }
        else if (parsedAuthResp.errors && parsedAuthResp.errors.length) {
            sendNotification({ msg: parsedAuthResp.errors[0], variant: 'error' });

            //alert(parsedAuthResp.errors[0])
        }
        else {
            sendNotification({ msg: 'error parsing auth Resp', variant: 'error' })
            //alert('error parsing auth Resp')
        }
        const email = authReq.Email;

        setAuth(parsedAuthResp);
        console.log(auth);

        /* set localStorage object to various tokens/users to perist user after login */
        localStorage.setItem('user', email);


        // // console.log(values.email, values.password);
        // /* can remove setStatus/setSubmitting once testing is done */
        // setStatus({ success: true });
        // setSubmitting(false);
        // alert('siginCallbak')
        setAuthzInProgress(false); 
        navigate(from, { replace: true });
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const ssoLogin = (providerName) => {
        // ecapAuthNZHelper.verifyAuthProcess().then((res) => {
        //     if (
        //       res.authN.state === authTokenHelper.AuthNZStatus.FAILED
        //     ) {
        //       window.location = '/#error?code=90000';
        //     }
        //     else if(res.authZ.state === authTokenHelper.AuthNZStatus.FAILED) {
        //       window.location = '/#error?code=90001';
        //     }
        //     else if(res.workerToken.state === authTokenHelper.AuthNZStatus.FAILED) {
        //       window.location = '/#error?code=90002';
        //     }
        //     else {
        //       setIsProtected(true);
        //     }
        //   });
        

        Auth.federatedSignIn({ provider: providerName, prompt: "select_account" })
        // .then(x => { console.log(x, "Success Prom Fed Signin");
        //     return Auth.currentAuthenticatedUser();
        // })
        // .then(user => {
        //         console.log(user);
        //         alert();
        //     })
            
        // // To derive necessary data from the provider
        // const {
        //     token, // the token you get from the provider
        //     domainOrProviderName, // Either the domain of the provider(e.g. accounts.your-openid-provider.com) or the provider name, for now the library only supports 'google', 'facebook', 'amazon', 'developer'
        //     expiresIn, // the time in ms which describes how long the token could live
        //     user,  // the user object you defined, e.g. { username, email, phone_number }
        //     identity_id // Optional, the identity id specified by the provider
        // } = getFromProvider(); // arbitrary function
        
        // Auth.federatedSignIn(
        //     domain,
        //     {
        //         token,
        //         identity_id, // Optional
        //         expires_at: expiresIn * 1000 + new Date().getTime() // the expiration timestamp
        //     },
        //     user
        // ).then(cred => {
        //     // If success, you will get the AWS credentials
        //     console.log(cred);
        //     return Auth.currentAuthenticatedUser();
        // }).then(user => {
        //     // If success, the user object you passed in Auth.federatedSignIn
        //     console.log(user);
        // }).catch(e => {
        //     console.log(e)
        // });
    }

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                {/* This section will open up a button to authenticate with a 3rd party. Saved to re-use when SSO is included in base project */}
                <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            onClick={() => ssoLogin('FCTAzure-OIDC')}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Sign in with Google
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500
                            }}
                            disableRipple
                            disabled
                        >
                            OR
                        </Button>

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Sign in with Email address</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {

                            Auth.signIn(values.email, values.password)
                            .then(user => {
                                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

                                    navigate('/ChangePassword', { state: { email: values.email, forceChange: true } });
                                    // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                                    // Auth.completeNewPassword(
                                    //     user,               // the Cognito User Object
                                    //     newPassword,       // the new password
                                    // ).then(user => {
                                    //     // at this time the user is logged in if no MFA required
                                    //     console.log(user);
                                    // }).catch(e => {
                                    //   console.log(e);
                                    // });
                                }
                                else{
                                    //alert('submit')
                                }
                            }).catch(e => {
                                //alert();
                                console.log(e);
                                setErrors({ submit: e.message });
                            });
                            setStatus({ success: false });
                            setSubmitting(false);

                            // return;

                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            /*  Error Handling for Cognito Error (400, 401, ect.) */

                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={e => { 
                                    handleChange(e); 
                                    setEmailInp(e.target.value);
                                }}
                                label="Email Address / Username"
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                            <Typography variant="subtitle1" color="primary" component={Link} sx={{ textDecoration: 'none', cursor: 'pointer' }} to='/ForgotPassword'>
                                Forgot Password?
                            </Typography>
                        </Stack>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                {/*<Button*/}
                                {/*    disableElevation*/}
                                {/*    disabled={isSubmitting}*/}
                                {/*    fullWidth*/}
                                {/*    size="large"*/}
                                {/*    type="submit"*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*>*/}
                                {/*    LOGIN*/}
                                {/*</Button>*/}
                                <LoadingButton
                                    type="submit"
                                    size="large"                                    
                                    loading={isSubmitting || authzInProgress}
                                    variant="contained"
                                    color="primary"                                    
                                    disableElevation
                                    fullWidth
                                >
                                    LOGIN
                                </LoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseLogin;
export async function parseAuthResponse(authResp, authConfig, setAuthNExpiresIn) {

    var parsedAuthResp = { errors: [], isSuccess: false };
    try{
        const userFromCog = await Auth.currentSession();
        // .then(user => {
        //     console.log({ user })
        //     alert(user)
        // })
        // .catch(err => {
        //     console.log(err)
        //     alert(err)
        // });

        if(userFromCog != null) {

        
        let roles = [];
        let authN_IdToken = userFromCog.getIdToken().jwtToken;
        let authN_AccessToken = userFromCog.getAccessToken().jwtToken;
        let authN_RefreshToken = null;
        let authZ_AccessToken = null;
        let ecap_WorkerToken = null;
        let fullName = '';

        if (authResp) {
            const authNResp = authResp.statuses.find(x => x.authType === 1);
            const authZResp = authResp.statuses.find(x => x.authType === 2);
            const ecapWorkerResp = authResp.statuses.find(x => x.authType === 3);
            if (true) {
                // if (authNResp.errorMessages && authNResp.errorMessages.length) {
                //     parsedAuthResp.errors = parsedAuthResp.errors.concat(authNResp.errorMessages.map(x => x.message));
                // }
                // else {
                    // if (authNResp.tokenInfo && authNResp.tokenInfo.idToken && authNResp.tokenInfo.accessToken) {
                    //     if(setAuthNExpiresIn) {
                    //         authNResp.tokenInfo.actualExpiresIn = authNResp.tokenInfo.expiresIn ;
                    //         const currentDate = new Date();
                    //         authNResp.tokenInfo.expiresIn = currentDate.setSeconds((currentDate.getSeconds() + authNResp.tokenInfo.expiresIn))/1000;
                    //     }

                        // authN_IdToken = authNResp.tokenInfo.idToken;
                        // authN_AccessToken = authNResp.tokenInfo.accessToken;
                        // authN_RefreshToken = authNResp.tokenInfo.refreshToken;

                        if (authZResp) {

                            if (authZResp.errorMessages && authZResp.errorMessages.length) {
                                parsedAuthResp.errors = parsedAuthResp.errors.concat(authZResp.errorMessages.map(x => x.message));
                            }
                            else {
                                if (authZResp.tokenInfo && authZResp.tokenInfo.accessToken) {
                                    authZ_AccessToken = authZResp.tokenInfo.accessToken;

                                    try {
                                        var tokenInfoJson = tokenParserInst.parseJWTPayload(authZ_AccessToken);
                                        if (tokenInfoJson.scopes) {
                                            var scopesJson = JSON.parse(tokenInfoJson.scopes);
                                            console.log(authConfig.applicationId, 'from auth config')
                                            roles = scopesJson[authConfig.tenantId][authConfig.applicationId] || [];
                                        }
                                    }
                                    catch {
                                        parsedAuthResp.errors.push('error parsing roles');
                                    }

                                    if (ecapWorkerResp) {
                                        if (ecapWorkerResp.errorMessages && ecapWorkerResp.errorMessages.length) {
                                            parsedAuthResp.errors = parsedAuthResp.errors.concat(ecapWorkerResp.errorMessages.map(x => x.message));
                                        }
                                        else {
                                            if (ecapWorkerResp.tokenInfo && ecapWorkerResp.tokenInfo.accessToken) {
                                                ecap_WorkerToken = ecapWorkerResp.tokenInfo.accessToken;

                                                try {
                                                    var ecapTokenInfoJson = tokenParserInst.parseJWTPayload(ecap_WorkerToken);
                                                    if (ecapTokenInfoJson['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']) {
                                                        fullName = ecapTokenInfoJson['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
                                                    }
                                                }
                                                catch {
                                                    parsedAuthResp.errors.push('error parsing roles');
                                                }

                                                parsedAuthResp.authN_IdToken = authN_IdToken;
                                                parsedAuthResp.authN_AccessToken = authN_AccessToken;
                                                parsedAuthResp.authN_RefreshToken = authN_RefreshToken;
                                                parsedAuthResp.authZ_AccessToken = authZ_AccessToken;
                                                parsedAuthResp.ecap_WorkerToken = ecap_WorkerToken;
                                                parsedAuthResp.roles = roles;
                                                parsedAuthResp.isSuccess = true;
                                                parsedAuthResp.email = authResp.email;
                                                parsedAuthResp.fullName = fullName;
                                            }
                                            else {
                                                parsedAuthResp.errors.push('no token info in ECAP worker Resp');
                                            }
                                        }
                                    }
                                    else {
                                        parsedAuthResp.errors.push('ECAP Worker token failed');
                                    }
                                }
                                else {
                                    parsedAuthResp.errors.push('no token info in authZ Resp');
                                }
                            }

                        }
                        else {
                            parsedAuthResp.errors.push('authZ failed');
                        }
                    // }
                    // else {
                    //     parsedAuthResp.errors.push('no token info in authN Resp');
                    // }
                // }

            }
            else {
                parsedAuthResp.errors.push('authN failed');
            }

        }
        else {
            parsedAuthResp.errors.push('Auth response is invalid.');
        }}
    }
    catch{
        parsedAuthResp.errors.push('authN not retrieved');
    }
    return parsedAuthResp;
}


import React from 'react';

// material-ui
import {
    Box,
    Container,
    Grid,
    Typography,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import CustomDialog from 'ui-component/extended/CustomDialog';

// ==============================|| FOOTER / DIALOG POPUP ||============================== //


const Footer = () => {
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const [openAbout, setOpenAbout] = React.useState(false);

    const handleOpenPolicy = () => setOpenPolicy(true);
    const handleClosePolicy = () => setOpenPolicy(false);

    const handleOpenAbout = () => setOpenAbout(true);
    const handleCloseAbout = () => setOpenAbout(false);

    const theme = useTheme();

    return (
        <>

            <Box bgcolor={theme.palette.primary.main} color="white">
                <Container maxWidth="xl">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={3} md={2}>
                            <Box borderBottom={1} sx={{ mt: 2 }}>Help</Box>
                            <Box>
                                <Button link={true.toString()} onClick={handleOpenAbout} sx={{ color: 'white' }}>About ECAP</Button>
                            </Box>
                            <Box>
                                <Button link={true.toString()} onClick={handleOpenPolicy} sx={{ color: 'white' }}>Privacy Policy</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}></Grid>
                        <Grid item xs={12} sm={1}>
                        </Grid>
                    </Grid>
                    <Box textAlign="center" pt={{ xs: 5, sm: 5 }} pb={{ xs: 5, sm: 2 }}>
                        Foster Care Technologies &reg; {new Date().getFullYear()}
                    </Box>
                </Container>
            </Box>
            <CustomDialog title='Privacy Policy' isOpen={openPolicy} handleClose={handleClosePolicy}>
                <Typography>Foster Care Technologies uses customer data to improve the products and services offered. Data is never shared with third parties and all data entered into systems maintained by Foster Care Technologies is owned by the customers. Customers have the option to delete this data when they terminate their contracts for products or services with Foster Care Technologies, LLC. </Typography>
            </CustomDialog>
            <CustomDialog title='About ECAP' isOpen={openAbout} handleClose={handleCloseAbout}>
                <Typography mt={2}>ECAP is a research backed, data-driven matching process, that helps Child Welfare Agencies identify the best possible Foster Care and Adoption placements for children in their care. You can think of ECAP as your personal Placement Assistant, which helps gather the relevant data, and then provides ways for you to use that data to make better decisions and achieve better outcomes. It helps you make sure that you maximize your time by calling the best suited families first and making sure you are not ignoring families that you may not have considered. </Typography>
                <Typography mt={2}>ECAP gives your staff the tools and information that they need to find those placements that are most likely to lead to safety and stability for each child or sibling group.</Typography>
                <Typography mt={2}>ECAP is a "best-of-breed" module, that can provide the placement engine for any CCWIS platform or other Case Management system. ECAP is also offered as a stand-alone web application that can be integrated with any other System of Record or used independently.</Typography>
                <Typography mt={2}>ECAP has already demonstrated results for improving outcomes. In an independent research study, placements that were guided by ECAP's decision process were found to be 22% more stable than placements that were not guided by ECAP. The study also showed that the children were reaching permanency 53 days sooner. Depending on the size of your agency these improved outcomes have the potential to reduce the cost of care by millions of dollars per year.</Typography>
                <Typography mt={2}>In 2014 our company, Foster Care Technologies, was formed specifically to help bring ECAP to agencies across the country. ECAP is at the center of an ongoing collaboration, among Social Service Agency Professionals, Child Welfare Researchers, and Technologists who are committed to the goal of creating better outcomes for children and families through better placements. ECAP has received national recognition from the most important organizations in child welfare and government IT. Contact us today to schedule a free demo, and to find out how we can help change lives for the better through better placements. </Typography>
            </CustomDialog>
        </>
    );
};

export default Footer;
import React, {useState, useEffect} from 'react';

import Moment from 'react-moment';

import { Link } from 'react-router-dom';

// material-ui
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Container,
    Button,
} from '@mui/material';

import {useIdleTimerContext} from 'react-idle-timer';

// assets
import MenuIcon from '@mui/icons-material/Menu';

import pages from 'menu-items/pages';
import useAuth from 'hooks/useAuth';
import useIdleTimer from 'hooks/useIdleTimer';

const ResponsiveAppBar = () => {
    // const { getRemainingTime, isPrompted, activate } = useIdleTimerContext()
    const {idleTimerConfig} = useIdleTimer();
    const { getRemainingTime, isPrompted, activate } = idleTimerConfig?.idleTimer || {};
    // Time before idle
    const [remaining, setRemaining] = useState(0);
    
  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted() || true) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const currentDate = new Date();
    const {auth} = useAuth();
    const pagesApplicable = pages
        .filter(page => !page.scope 
                        || (typeof page.scope === 'string' && auth?.roles?.includes(page.scope))
                        || (typeof page.scope === 'object' && page.scope.anyOf && page.scope.anyOf.length && auth?.roles?.some(authRole => page.scope.anyOf.includes(authRole)))
                )

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar style={{ background: 'primary' }} position="static">
            <Container maxWidth='100%'>
                <Toolbar variant="dense" disableGutters>
                        {/* Collapsed Menu items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="medium"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pagesApplicable.map((pages) => {
                                
                                return (
                                    <MenuItem key={pages.id} onClick={handleCloseNavMenu} component={Link} to={pages.url} >
                                        <Typography textAlign="center">{pages.title}</Typography>
                                        {/*{open ? <ExpandLess /> : <ExpandMore />}*/}
                                    </MenuItem>
                                )
                            })}                            
                        </Menu>
                    </Box>
                        {/* Desktop Menu items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesApplicable.map((pages) => (
                            
                        <Button
                            key={pages.id}
                            sx={{ color: 'white', display: 'block', marginLeft: '5px' }}
                            component={Link}
                            to={pages.url}
                        >
                            {pages.title}
                        </Button>
                        ))}
                            {/* Menu Item with Sub-Menu if needed in future */}
                    {/*    <Button*/}
                    {/*        id="demo-customized-button"*/}
                    {/*        aria-controls={open ? 'demo-customized-menu' : undefined}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        sx={{ color: 'white', marginLeft: '5px' }}*/}
                    {/*        aria-expanded={open ? 'true' : undefined}*/}
                    {/*        disableElevation*/}
                    {/*        onClick={handleClick}*/}
                    {/*        endIcon={open ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*    >*/}
                    {/*        Matching*/}
                    {/*    </Button>*/}
                    {/*    <Menu                                                              */}
                    {/*        anchorEl={anchorEl}*/}
                    {/*        open={open}*/}
                    {/*        onClose={handleClose}*/}
                    {/*        elevation={0}*/}
                    {/*        anchorOrigin={{*/}
                    {/*            vertical: 'bottom',*/}
                    {/*            horizontal: 'left',*/}
                    {/*        }}*/}
                    {/*        PaperProps={{*/}
                    {/*            sx: {*/}
                    {/*                bgcolor: '#006EA5'*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*        <MenuItem onClick={handleClose} disableRipple>*/}
                    {/*            <Button*/}
                    {/*                key='1'*/}
                    {/*                sx={{ color: 'white', marginLeft: '5px' }}*/}
                    {/*                component={Link}*/}
                    {/*                to='/BestMatchPage'*/}
                    {/*            >*/}
                    {/*                Foster Care*/}
                    {/*        </Button>*/}
                    {/*        </MenuItem>*/}
                    {/*        <MenuItem onClick={handleClose} disableRipple>*/}
                    {/*            <Button*/}
                    {/*                key='2'*/}
                    {/*                sx={{ color: 'white', marginLeft: '5px' }}*/}
                    {/*                component={Link}*/}
                    {/*                to='/login'*/}
                    {/*            >*/}
                    {/*                Adoption*/}
                    {/*        </Button>*/}
                    {/*        </MenuItem>*/}
                    {/*    </Menu>*/}
                    </Box>                  

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }  }}>
                        <Moment format="dddd MMMM DD, YYYY">{currentDate}</Moment>
                    </Box>
                </Toolbar>
            </Container>
            </AppBar>
            </Box>
    );
};
export default ResponsiveAppBar;

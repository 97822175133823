import { Box, Button, CircularProgress, Grid, Link, Typography } from "@mui/material"
import { DataGridPremium, GridActionsCellItem, GridColDef, GridToolbar } from '@mui/x-data-grid-premium';
import { ArrowDropDown, Edit, OpenInNew } from '@mui/icons-material';
import useECAPAxios from "utils/ecapAxios";
import { useEffect, useState } from "react";
import { useEcapSnackNotificationHTML } from "hooks/useEcapSnackNotification";
import ConfirmDialog from "ui-component/extended/ConfirmDialog";
import MainCard from "ui-component/cards/MainCard";
import { useParams } from "react-router";
import FilePresentIcon from '@mui/icons-material/FilePresent';

const SafeSummaryDetail = (props) => {
    const useAxiosInstance = useECAPAxios();
    let { safeSummaryId } = useParams();
    if(!safeSummaryId){
        safeSummaryId = props.safeSummaryId;
    }
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const getAttachmentUrl = `ffp/api/placement-referral/attachment/`;
    const [{loading: attachmentFetchInProgress}, getAttachmentApi] = useAxiosInstance(getAttachmentUrl, { manual: true });
    const [{loading: getSafeSummaryDetailsInProgress}, getSafeSummaryDetailsApi] = useAxiosInstance(`ffp/api/placement-referral/safe-summary/${safeSummaryId}`, { manual: true });
    const [confirmDialog, setConfirmDialog] = useState<any>({ isOpen: false, msg: '', title: '' });
    const [safeSummaryDetails, setSafeSummaryDetails] = useState<any>({});
    const [downloadIdInProgress, setDownloadIdInProgress] = useState<any>(null);

    useEffect(() => {
        getSafeSummaryDetailsApi()
        .then((resp) => {
            if(resp.data.isInErrorState) {
                setSnackbar({
                    html: `Error getting safe summary details. ${resp.data.validationMessages.map(x => x.message).join("<br />")}`,
                    variant: "error",
                  })
            }
            else{
                setSafeSummaryDetails(resp.data.data);
            }
        })
        .catch(err => {
            setSnackbar({
                html: `Error getting safe summary details.`,
                variant: "error",
              })
        })
    }, [])

    const downloadAttachment = (attachmentId, fileName) => {
        if(downloadIdInProgress) {
            setSnackbar({
                html: `Another download in progress. Please try after some time.`,
                variant: "error",
              })
            return;
        }
        setDownloadIdInProgress(attachmentId);
        getAttachmentApi({url: getAttachmentUrl + attachmentId, headers: {}, responseType: 'blob'})
        .then((resp => {
            const fileURL = URL.createObjectURL(resp.data);
            // window.open(fileURL)
            var link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `${fileName}`);
            // link.setAttribute('target','_blank');
            document.body.appendChild(link);    
            link.click();
        }))
        .catch(err => {
            setSnackbar({
                html: `Error downloading file ${fileName}.`,
                variant: "error",
              })
        })
        .finally(() => {
            setDownloadIdInProgress(null);
        })
    }

    const TextWithLabel = ({label, text}) => {
        return (<Grid container sx={{p: 1}}>
                <Grid xs={6} sm={6} md={6} lg={2} sx={{fontWeight: 'bold'}}>{label}:</Grid>
                <Grid xs={6} sm={6} md={6} lg={10}>{text}</Grid>
        </Grid>);
    }

    const familyRespColumns = [
        {
            field: 'familyName',
            headerName: 'Provider',            
            flex: 1,
        },
        {
            field: 'sentDate',
            headerName: 'Sent',
            flex: 1,
        },  
        {
            field: 'response',
            headerName: 'Response',
            renderCell: (params: GridRenderCellParams) => {
                const color = params.value == "Interested" ? "green" : (params.value == "Possibly Interested" ? "orange" : "black" );
                return (<h3 style={{ color: color }}> {params.value} </h3>);
            },
            flex: 1
        },
        {
            field: 'responseTime',
            headerName: 'Response Time',        
            flex: 1,
        },
    ]
    

    return ( 
    <MainCard
        title=""
        sx={{ m: 1, p: 3 }}
    >
        <Grid>
            {getSafeSummaryDetailsInProgress
                ? <CircularProgress />
                : <div style={{display: 'flex', minHeight: 500 }}>
                    <Box sx={{flexGrow: 1 }}>
                    <Typography variant="h3">{safeSummaryDetails?.clientName} - Safe Summary</Typography>
                        <Box sx={{flexGrow: 1 }}>
                            <TextWithLabel label="Date of Safe Summary" text={safeSummaryDetails?.ReferralDate} />
                            <TextWithLabel label="Safe Summary Id" text={safeSummaryDetails?.ReferralId} />
                            <TextWithLabel label="Age" text={safeSummaryDetails?.age} />
                            <TextWithLabel label="Gender" text={safeSummaryDetails?.gender} />
                            <TextWithLabel label="Ethnicity" text={safeSummaryDetails?.ethnicity} />
                            <TextWithLabel label="Race" text={safeSummaryDetails?.race} />
                            <TextWithLabel label="Case Plan Goal" text={safeSummaryDetails?.casePlanGoal} />
                            <TextWithLabel label="Level of Care" text={safeSummaryDetails?.levelOfCare} />
                            <TextWithLabel label="CMP" text={safeSummaryDetails?.cmp} />
                            <TextWithLabel label="Date of Referral" text={safeSummaryDetails?.refDate} />
                            <TextWithLabel label="Date Placement Needed" text={safeSummaryDetails?.placementNeededDate} />
                            <TextWithLabel label="Date Client Entered Care" text={safeSummaryDetails?.childEntercCareDt} />
                            <TextWithLabel label="Child's Current County" text={safeSummaryDetails?.county} />
                            <TextWithLabel label="Behaviors & Characteristics" text={safeSummaryDetails?.behaviors} />
                            <TextWithLabel label="Additional Referral Notes" text={safeSummaryDetails?.additionalRefNotes} />
                        </Box>
                        <Box sx={{flexGrow: 1 }}>
                            <h2 style={{color: 'orange'}}>Attachments Sent with Safe Summary</h2>
                            <div style={{padding: 1}}>
                                {safeSummaryDetails?.FamilyAttachments?.length
                                    ? safeSummaryDetails?.FamilyAttachments?.map(att => {
                                            return (
                                                <Grid sx={{padding: 1, flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                                                    <h4 style={{paddingRight: 10, color: 'blue'}}>{att.attachmentName}</h4>
                                                    <div><FilePresentIcon onClick={(e) => {
                                                            downloadAttachment(att.id, att.attachmentName)
                                                        }}
                                                    /></div>
                                                    <div>
                                                        {downloadIdInProgress == att.id && <CircularProgress />}
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    : "There are no attachments."
                                }
                            </div>
                        </Box>
                        <Box sx={{flexGrow: 1 }}>
                        <h2 style={{color: 'orange'}}>Other Attachments - Not sent to providers</h2>
                            <div style={{padding: 1}}>
                                {safeSummaryDetails?.WorkerAttachments?.length
                                    ?   safeSummaryDetails?.WorkerAttachments?.map(att => {
                                            return (
                                                <Grid sx={{padding: 1, flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                                                     <h4 style={{paddingRight: 10, color: 'blue'}}>{att.attachmentName}</h4>
                                                    <div>
                                                        <FilePresentIcon onClick={(e) => {
                                                                downloadAttachment(att.id, att.attachmentName)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        {downloadIdInProgress == att.id && <CircularProgress />}
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    : "There are no attachments."
                                }
                            </div>
                        </Box>
                        <Box sx={{flexGrow: 1 }}>
                            <h2 style={{color: 'orange'}}>Safe Summary Sent</h2>
                            <div style={{ display: 'flex', height: 500}}>
                                <Box sx={{flexGrow: 1 }}>
                                    {safeSummaryDetails?.FamilyResponses?.length 
                                        ? <DataGridPremium
                                                rows={safeSummaryDetails?.FamilyResponses}
                                                columns={familyRespColumns}
                                                hideFooterRowCount                        
                                                disableSelectionOnClick
                                                sortable={false}
                                            />
                                        : "No data"
                                    }
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </div>
            }
        </Grid>
    </MainCard>
    )
}
export default SafeSummaryDetail
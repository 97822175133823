import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'AuthNZ/RequireAuth';
import { ScopeConstants } from 'services/constants/scope-constants';
import SafeSummaryDetail from 'views/pages/ffp/referrals/safe-summary-detail';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// page routing
const BestMatchPage = Loadable(lazy(() => import('views/pages/best-match')));
const ReverseMatchPage = Loadable(lazy(() => import ('views/pages/reverse-match')));
const AdminPage = Loadable(lazy(() => import('views/pages/admin')))
const AwaitingPlacementPage = Loadable(lazy(() => import('views/pages/awaiting-placement')))
const ReportsPage = Loadable(lazy(() => import('views/pages/reports')))
const SearchPage = Loadable(lazy(() => import('views/pages/search')))
const ClientProfile = Loadable(lazy(() => import('views/pages/client-profile')))
const ProviderProfile = Loadable(lazy(() => import('views/pages/provider-profile')))
const Support = Loadable(lazy(() => import('views/pages/support/support.tsx')))
const PlacementReferralsPage = Loadable(lazy(() => import('views/pages/ffp/referrals/placement-referrals.tsx')))
const UnAuthorized = Loadable(lazy(() => import('views/pages/authentication/UnAuthorized.tsx')))

// ==============================|| MAIN ROUTING ||============================== //
//Routes are protected by the "RequireAuth" route that can be found in the AuthNZ folder.
//This handles all the access needs of any individual route to protect them both by authentication
//but also authorization.
const MainRoutes = {
    element: <RequireAuth />,
    children: [{
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: '/dashboard/default',
                element: <DashboardDefault />
            },
            {
                path: '/Placement/Referrals',
                element: <PlacementReferralsPage />
            },
            {
                path: '/Placement/Referrals/:safeSummaryId',
                element: <SafeSummaryDetail />
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.ADMIN_LIST_USERS, ScopeConstants.ADMIN_LIST_AGENCIES, ScopeConstants.ADMIN_DATA_ALERTS, ScopeConstants.FFP_GETALLREGISTRATIONS]}} />,
                children: [{
                    path: '/Admin',
                    element: <AdminPage />
                }]     
                // path: '/Admin',
                // element: <AdminPage />           
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.AWAITINGPLACEMENTLIST, ScopeConstants.RECENTLYREMOVEDAWAITINGPLACEMENTLIST]}} />,
                children: [{
                    path: '/AwaitingPlacement',
                    element: <AwaitingPlacementPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.CLIENTREPORTS, ScopeConstants.PROVIDERREPORTS]}} />,
                children: [{
                    path: '/Reports',
                    element: <ReportsPage />
                }]
            },
            {
                path: '/BestMatchPage',
                element: <BestMatchPage />
            },
            {
                path: '/ReverseMatchPage/:providerId/:matchType',
                element: <ReverseMatchPage />
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SEARCHPROVIDER, ScopeConstants.SEARCHCLIENT]}} />,
                children: [{
                    path: '/Search',
                    element: <SearchPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READCLIENT} />,
                children: [{
                    path: '/ClientProfile',
                    element: <ClientProfile />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READPROVIDER} />,
                children: [{
                    path: '/Provider/Profile/:providerId',
                    element: <ProviderProfile />
                }]
            },  
            {
                path: '/support',
                element: <Support />
            },  
            {
                path: '/Unauthorized',
                element: <UnAuthorized />
            },
            
            {
                // commented as we are auto generating a route while displaying pdf.
                // will figure out a way and update in upcoming PR.
                // path: '*',
                // element: <DashboardDefault />
            }
        ]
    }]
};

export default MainRoutes;

import React from 'react'
import PropTypes from 'prop-types';


// material-ui
import {
    Box,
    Container,
    Grid,
    Dialog,
    Typography,
    DialogContent,
    DialogTitle,
    DialogActions,
    useMediaQuery,
    IconButton,
    Button
} from '@mui/material';

import { useTheme, styled } from '@mui/material/styles'

//assets
import { Close } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        fontSize: '1.25em',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        bgcolor: 'background.paper',
        borderTop: '3px solid',
        borderTopColor: theme.palette.secondary.main,
    }
}));


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    actions: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const CustomDialog = ({ title, children, actions = null, isOpen, handleClose, fullscreen = false, fullWidth = false, maxWidth = 'sm'}) => {
    const theme = useTheme();
    let fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    fullScreen = fullscreen ? true : fullScreen;

    return (
        <>
            <BootstrapDialog
                open={isOpen}
                aria-labelledby="customized-dialog-title"
                onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth={ fullWidth}
                maxWidth={ maxWidth}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    { title }
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    { children }
                </DialogContent>
                {actions && <DialogActions>
                    {actions}
                </DialogActions>}
            </BootstrapDialog>

        </>
        );
}

export default CustomDialog
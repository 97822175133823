export const ScopeConstants = {
    DASHBOARD_ASSIGNED_CASES: "AssignedCases",
    DASHBOARD_DATA_ALERTS: "DataAlertsCount",
    DASHBOARD_OTHER: "OtherDashboard",

    ADMIN_LIST_USERS: 'ListUsers',
    ADMIN_SAVE_USER: 'CreateUser',
    ADMIN_LIST_AGENCIES: 'ListAgencies',
    ADMIN_SAVE_AGENCY: 'CreateAgency',
    ADMIN_DATA_ALERTS: 'DataAlertsDetails',

    AWAITINGPLACEMENTLIST: 'ReadIntake',
    SAVEINTAKE: 'SaveIntake',
    RECENTLYREMOVEDAWAITINGPLACEMENTLIST: 'ReadRemovedIntake',
    ADDBACKTOLIST: 'AddBackRemovedIntake',

    CLIENTREPORTS: 'ClientReports',
    PROVIDERREPORTS: 'ProviderReports',

    READPLACEMENT: 'ReadPlacement',
    SAVEPLACEMENT: 'SavePlacement',
    DELETEPLACEMENT: 'DeletePlacement',

    READCLIENT: 'ReadClient',
    SAVECLIENT: 'SaveClient',
    DELETECLIENT: 'DeleteClient',
    SEARCHCLIENT: 'SearchClient',
    CLIENTHISTORY: 'ClientHistory',

    READPROVIDER: 'ReadProvider',
    SAVEPROVIDER: 'SaveProvider',
    DELETEPROVIDER: 'DeleteProvider',
    SEARCHPROVIDER: 'SearchProvider',

    FFP_GETALLREGISTRATIONS: 'GetAllRegistrations',

    MODIFYASSESSMENTLOC: 'ModifyAssessmentLoc',
}
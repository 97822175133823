// material-ui
import logo from 'assets/images/ECAP_Logo_SVG.svg';

//Georgia Demo
//import logo from 'assets/images/georgia_logo.png'

//FFP Demo
//import logo from 'assets/images/FFP_SVG_BLUE.png';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    

    return (
       
        <img src={logo} alt="ECAP" width='90'/>
    );
};

export default Logo;

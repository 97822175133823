import React from 'react';
import {
    Paper,
    Slide,
    Button,
    Tooltip,
    Grid,
    TextField,
    Typography,
    Fab,
    CircularProgress,
    IconButton
    
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme, styled } from '@mui/material/styles';

import { green } from '@mui/material/colors';
import EmailIcon from '@mui/icons-material/Email';

import CloseIcon from '@mui/icons-material/Close';



import useECAPAxios from 'utils/ecapAxios';
import { useEcapSnackNotificationHTML } from 'hooks/useEcapSnackNotification';
import { Formik, FormikHelpers, FormikValues, FormikProps, Form, Field, FastField } from 'formik';
import * as yup from 'yup';
import { FormTextField } from 'ui-component/extended/FormTextField';
import { shouldComponentUpdate } from 'ui-component/extended/FormMenuField';

const useStyles = makeStyles((theme) => ({
    root: {        
        alignItems: 'center',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: theme.spacing(40),
        },
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },    
    submitButton: {
        bottom: theme.spacing(1),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '90%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    paper: {
        zIndex: 9999,
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(1),
        //height: theme.spacing(55),
        width: theme.spacing(45),
        margin: theme.spacing.unit,
        borderColor: theme.palette.secondary.main,
        borderTop: 3,
        borderTopStyle: 'solid',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: theme.palette.grey[500],
    },
    titleText: {
        margin: theme.spacing(2)
    }
}));
const validationSchema = yup.object({
    name: yup
      .string()
      .required('Name is required'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    comments: yup
      .string()
      .required('Comments are required')
  })

export default function ContactUs() {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [apiErrors, setApiErrors] = React.useState([]);
    const [formInitialValues, setFormInitialValues] = React.useState({name: '', email: '', comments: ''}); 
    const timer = React.useRef();
    const theme = useTheme();
    
    const useAxiosInstance = useECAPAxios();
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const [{ loading: contactUsInProgress, error: contactUsApiError }, ContactUsApi] = useAxiosInstance({
        url: `/api/contact-us`,
        method: 'POST',
      }, { manual: true });

    const handleChange = () => {
        setChecked(!checked);
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = (formikProps, formikHelpers) => {
        setSuccess(false);
        setLoading(true);
        setApiErrors([]);
        var postData = {
            Name: formikProps.name,
            Email: formikProps.email,
            Comments: formikProps.comments
        }
        ContactUsApi({data: postData}).then(data => {
            if(data.data.isInErrorState) {
                setApiErrors(data.data.validationMessages.map(x => x.message));
            }
            else{
                setSuccess(true);
                formikHelpers.resetForm();
                setChecked(false);
                setSnackbar({
                    html: 'Contact Form Submitted successfully.',
                    variant: "success",
                });
            }
            setLoading(false);
        }).catch(err => {
            setApiErrors(['Unknown error submitting form.']);
            setLoading(false);
        });
    };

    return (
        <div style={{alignItems: 'center',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: theme.spacing(40),
        }}}>           
            <div style={{margin: theme.spacing(1), position: 'relative',}}>
                <Tooltip title="Contact Us" aria-label="Contact Us">
                    <Fab color="primary" className={classes.fab} aria-label="Contact Us" onClick={handleChange}><EmailIcon /></Fab>
                </Tooltip>
                <Slide direction="left" in={checked} mountOnEnter unmountOnExit>
                    <Paper elevation={3} className={classes.paper} sx={{ p: 2 }}>
                        <Grid justify="space-around" align="center" container spacing={0} >
                            <Grid item>
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleChange}>
                                    <CloseIcon />
                                </IconButton>
                                <Typography className={classes.titleText}>
                                    <b>CONTACT US</b>
                                </Typography>
                                <Typography component="p" sx={{textAlign: 'start'}}>
                                Use this form to let us know about any issues with ECAP: support requests, comments, or anything else. We will respond.
                                </Typography> 
                                <ul style={{textAlign: 'start', color: 'red'}}>
                                    {apiErrors.map(x => <li>{x}</li>)}
                                </ul>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                    <Formik
                                        initialValues={formInitialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, formikHelpers) => {
                                            formikHelpers.setSubmitting(false);
                                            handleButtonClick(values, formikHelpers);
                                        }}
                                    >
                                        {(formikProps, formikHelpers) => (
                                            <Form noValidate autoComplete="off" className={classes.root} onSubmit={
                                                e =>  { 
                                                    formikProps.validateForm();
                                                    formikProps.handleSubmit(e);
                                                }
                                            }>
                                            <Grid item>
                                                <FastField
                                                    id="name-required"
                                                    name="name"
                                                    label="Name"
                                                    required
                                                    fullWidth
                                                    component={FormTextField}
                                                    shouldUpdate={ shouldComponentUpdate }
                                                />
                                                <FastField
                                                    id="email-required"
                                                    name="email"
                                                    label="Email"
                                                    required
                                                    fullWidth
                                                    component={FormTextField}
                                                />
                                                <Field
                                                    id="outlined-required"
                                                    name="comments"
                                                    label="Comment"
                                                    variant="outlined"
                                                    multiline
                                                    required
                                                    rows={5}
                                                    fullWidth
                                                    component={FormTextField}
                                                />
                                                </Grid>
                                                <Grid item sx={{mt:1}}>
                                                    <Button color="primary" variant="outlined" className={classes.submitButton} disabled={loading} type="submit">
                                                        Submit                                         
                                                    </Button>
                                                    {loading && <CircularProgress size={24} thickness={5} className={classes.buttonProgress} />}
                                                    
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                            </Grid>
                        </Grid>
                    </Paper>
                </Slide>
            </div>
        </div>
    );
}

import axios from 'axios';
import { makeUseAxios } from 'axios-hooks'
import useAuth from 'hooks/useAuth';
import useAuthConfig from 'hooks/useAuthConfig';
import { useNavigate } from 'react-router';
import config from '../config'
import useAuthZAxios, { getAuthzToken } from './authZAxios';
import jwt from 'jsonwebtoken';
import jwkToPem  from 'jwk-to-pem'

const maxRetries = 2;
let currentRetries = 0;
const useECAPAxios = () => {
    const navigate = useNavigate();
    const useAuthZAxiosInst = useAuthZAxios();
    const {auth, setAuth} = useAuth();
    const { authConfig, setAuthConfig } =  useAuthConfig();
    const axiosInst = axios.create({ baseURL: config.baseApiUrl });
    const [{ loading: authzTokenLoading }, getTokenFromAuthZ] = useAuthZAxiosInst({
        url: '/api/Authorize',
        method: 'POST',
    }, { manual: true });

    const verifyToken = (token) => {
        var jwkskeys = authConfig.keys

        //Decode token
        var decoded = jwt.decode(token, {complete: true});

        //Get the correct key from the jwks based on the kid
        var jwk = jwkskeys.filter(function(v) {
            return v.kid === decoded.header.kid;
        })[0];

        //Convert the key to pem
        var pem = jwkToPem(jwk);

        var tokenError = null;
        //Verify the token with the pem
        var verRes =  jwt.verify(token, pem, function(err, decoded) {
            //if decoded exists, its valid
            console.log(err)
            tokenError = err;

            if(tokenError) {
                if(!window.location.href?.toLowerCase()?.includes("/login"))
                    navigate('/Login')
            }
        });
    }
    
    // Add a request interceptor
    axiosInst.interceptors.request.use(function (config) {
        // Do something before request is sent
        if(!config.headers.common['Content-Type']) {
            config.headers.common['Content-Type'] = 'application/json';
        }
        
        if(auth.authN_IdToken) {
            verifyToken(auth.authN_IdToken);
            config.headers.common['Authorization'] = `Bearer ${auth.authN_IdToken}`;
        }
        else{
            navigate('/Login')
        }
        if(auth.authZ_AccessToken) {
            config.headers.common['JAuthorization'] = `Bearer ${auth.authZ_AccessToken}`;
        }
        
        if(auth.ecap_WorkerToken) {
            config.headers.common['ECAPWorkerAuthorization'] = `Bearer ${auth.ecap_WorkerToken}`;
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    axiosInst.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(error && error.response) {
            if (error.response.status === 401) {
                navigate("/Login")
            }
            else if (error.response.status === 403) {
                // alert('403 AuthZ error. Handle');
                navigate("/Unauthorized")
                const authzResp = await getTokenFromAuthZ({data: {
                    tenantId: authConfig.tenantId,
                    appId: authConfig.applicationId,
                    token: auth.authN_IdToken
                }});
                if(authzResp.data && authzResp.data.token) {
                    auth.authZ_AccessToken = authzResp.data.token;
                    setAuth(auth);

                    if(currentRetries < maxRetries) {
                        axiosInst(error.config);
                        currentRetries ++;
                    }
                    else{
                        navigate("/Unauthorized")
                    }
                }
                else{
                    navigate("/Login")
                }
            }
            else if (error.response.status === 470 || error.response.status === 471 || error.response.status === 472) {
                const ecapWorkerResp = await axiosInst({
                    url: 'api/worker/token',
                    method: 'POST',
                    data: {
                        AuthZToken: auth.authZ_AccessToken
                    }
                })
                    .then(res => res.data);
                if(ecapWorkerResp.isInErrorState) {
                    alert('error fetching worker token. Redirect to Login page.');
                    navigate("/Login");
                }
                else { 
                    auth.ecap_WorkerToken = ecapWorkerResp.token;
                    setAuth(auth);

                    axiosInst(error.config);
                }
                console.log(ecapWorkerResp, 'worker token resp')
            }
        }

        return Promise.reject(error);
    });
    
    return makeUseAxios({
      axios: axiosInst,
      defaultOptions: {autoCancel:false}
    });
}
export default useECAPAxios;
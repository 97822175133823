// assets
import { IconKey } from '@tabler/icons';
import { ScopeConstants } from 'services/constants/scope-constants';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = [
    {
        id: 'view-dashboard',
        title: 'Dashboard',
        url: '/'
    },
    {
        id: 'view-referrals',
        title: 'Placement Referrals',
        url: '/Placement/Referrals'
    },    
    {
        id: 'pages-awaiting-placement',
        title: 'Clients Awaiting Placement',
        url: '/AwaitingPlacement',
        scope: { anyOf: ['ReadIntake', 'ReadRemovedIntake'] }
    },
    {
        id: 'pages-reports',
        title: 'Reports',
        url: '/Reports',
        scope: { anyOf: ['ClientReports', 'ProviderReports'] }
    },
    {
        id: 'pages-admin',
        title: 'Admin',
        url: '/Admin',
        scope: { anyOf: ['ListUsers', 'ListAgencies', 'DataAlertsDetails', ScopeConstants.FFP_GETALLREGISTRATIONS] }
    },
    {
        id: 'pages-search',
        title: 'Search',
        url: '/Search'
    },
    {
        id: 'pages-support',
        title: 'support',
        url: '/support'
    }
]

export default pages;

import { CircularProgress } from '@mui/material';
import { Auth } from 'aws-amplify';
import useAxios from 'axios-hooks';
import useAuthConfig from 'hooks/useAuthConfig';
import { useEcapSnackNotificationHTML } from 'hooks/useEcapSnackNotification';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useECAPAxios from 'utils/ecapAxios';
import { parseAuthResponse } from 'views/pages/authentication/auth-forms/AuthLogin';
import config from '../config'

const AppSettingsContext = createContext({});

export const AppSettingsProvider = ({ children }) => {
    const useAxiosInst = useAxios();
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const [{ loading: custSettingsFetchInProgress }, getCustSettingsApi] = useAxios(config.baseApiUrl + `/api/metadata/wapp-settings`, { manual: true });
    const [loadComplete, setLoadComplete] = useState(false);
    const [appSettings, setAppSettings] = useState({a: 1});

    useEffect(() => {
        getCustSettingsApi()
        .then(resp => {
            setAppSettings(resp.data.data);
            setLoadComplete(true);
        })
        .catch(err => {
            setSnackbar({
                html: "Error getting customer settings.",
                variant: "error",
              })
              setLoadComplete(true);
        })
    }, [])

    return !loadComplete 
            ? (<CircularProgress />)
            : (<AppSettingsContext.Provider value={{ appSettings, setAppSettings }} >
                {children}
              </AppSettingsContext.Provider>)
        
}

export default AppSettingsContext;
export const PlacementAttributeConstants = {
    NEXT_LIVING_SITUATION: "NEXT_LIVING_SITUATION",
    PLACEMENT_END_REASON: "PLACEMENT_END_REASON",
    PALCEMENT_TYPE: "PL:PLACEMENTTYPE",
    PLACEMENT_NOTES: "PL_PLACEMENTNOTES",
    PL_LEVELOFCARE:"PL:LEVELOFCARE",
    PLACEMENT_REASON: "PLACEMENT_REASON",
    REASON_FOR_MOVE: "REASON_FOR_MOVE",
    PLACEMENT_PROGRAM: "PL_PLACEMENTPROGRAM"
}

export const AssessmentAttributeConstants = {
    LEVELOFCARE: "LEVELOFCARE"
}

export const GeneralConstants = {
    DateFormat: "MM/DD/YYYY",
    DateTimeFormat: "MM/DD/YYYY HH:mm:ss"
}

export const AssessmentFieldTypes = {
    Attributes: "AssessmentAttributes",
    EnvPrefs: "EnvironmentPrefs",
    Questions: "Questions"
}

export const ProviderFieldTypes = {
    ProviderName: "PROVIDERNAME",
}

export const ClientFieldTypes = {
    ClientProgram: "CLIENTPROGRAM",
}

export const PlacementRequestFieldTypes = {
    ClientAdoptionProgram: 'CLIENTADOPTIONPROGRAM'
}

export const ClientFieldPosVals = {
    AdoptionProgram: 'ADOPTIONPROGRAM'
}

export const AwaitingPlacementFieldTypes = {
    PrimaryMatchingNeed: "PRIMARYMATCHINGNEED"
}

export const IntakeFieldPosVals = {
    PRIMMATCHNEED_FOSTER: "FOSTER",
    PRIMMATCHNEED_FOSTER_Display: "Foster Placement",
    PRIMMATCHNEED_ADOPTION: "ADOP",
    PRIMMATCHNEED_ADOPTION_Display: "Adoption Placement"
}

export const PlacementFieldPosVals = {
    PLACEMENTTYPE_NONRESPITE: "NONRESPITE",
    PLACEMENTTYPE_NONRESPITE_Display: "Non Respite",
    NEXTLIVINGSITUATION_ADOPTIONPROGRAMPLACEMENT: "ADOPPROGPLACEMENT"
}

export const QueryParamsConstants = {
    BmlPage_MatchingNeed: "need",
    BmlPage_ClientId: "clientId"
}

export const UIProviderTypeName = {
    ADOPTIONPROVIDERNAME: "Best Matched Adoption Providers",
    FOSTERPROVIDERNAME: "Best Matched Foster Providers",
}

export const UICallLogTypeName = {
    ACCEPTED: "Accepted Providers",
    INMATCHING: "In Matching Process Providers",
    PENDING: "Pending Providers",
    INITIALCONSIDERATION: "Initial Consideration Providers"

}

export const UIBMPTitle = {
    FOSTER: "Best Foster Match",
    ADOPTION: "Best Adoption Match"
}

export const AdminTabNames = {
    DATA_ALERTS_MAINTAB: 'DataAlerts',
    DATA_ALERTS_SUBTAB_PLRESTR: 'PlRestr',
    DATA_ALERTS_SUBTAB_INCOMPLPROVPROF: 'IPP'
}